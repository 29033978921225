<template>
  <div class="page">
    <div class="title">
      <span style="padding-left: 0.5rem;"> 甄选商品 专属服务</span>
      <div class="context">
        <span>在线提交采购需求</span>
      </div>
      <div class="content">
        <div class="item">
          <div class="lefttitle" @click="showSelect"> 城市<span class="red">*</span></div>
          <van-field @click="showSelect" v-model="form.cityName" readonly placeholder="请选择企业所在城市" />
          <van-icon size="1.1rem" color="#999" name="arrow" />
        </div>
        <div class="item">
          <div class="lefttitle"> 公司名称<span class="red">*</span></div>
          <van-field v-model="form.companyName" placeholder="请输入公司名称" />
        </div>
        <div class="item">
          <div class="lefttitle"> 您的姓名<span class="red">*</span></div>
          <van-field v-model="form.userName" placeholder="请输入联系人姓名" />
        </div>
        <div class="item">
          <div class="lefttitle"> 手机号码</div>
          <van-field type="tel" v-model="form.userPhone" placeholder="请输入联系人手机号" />
        </div>
        <div class="item">
          <div class="lefttitle"> 座机号码</div>
          <van-field v-model="form.contactLandline" placeholder="请输入联系人座机号" />
        </div>
        <div class="want"> 购买意向<span class="red">*</span> <span style="font-size: 0.85rem;color: #666;"> (可多选)</span>
        </div>
        <div class="wantcontent">
          <span :class="item.check ? 'wantitemSelect' : 'wantitem'" v-for="(item, index) in want"
            @click="checkWant(item)">
            {{ item.str }} </span>
        </div>
      </div>
      <div class="foot-btn" @click="onSubmit">提交</div>
    </div>
    <van-popup v-model="show" round :style="{ height: '37%', width: '90%' }">
      <van-area title="请选择选择" :area-list="areaList" value="110101" @confirm="confirm" @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import area from "../../lip/area";
export default {
  name: '',
  data() {
    return {
      show: false,
      areaList: area,
      form: {
        cityName: '',
        companyName: '',
        contactLandline: '',
        intentionDetail: '',
        userName: '',
        userPhone: '',
      },
      want: [
        { str: '节日福利采购', check: false },
        { str: '年度采购', check: false },
        { str: '劳保福利', check: false },
        { str: '食堂采买', check: false },
        { str: '需要沟通确认', check: false },

      ]

    }
  },
  created() { },
  components: {},
  methods: {
    checkWant(item) {
      item.check = !item.check
    },
    cancel() {
      this.show = false
    },
    showSelect() {
      this.show = true
    },
    confirm(val) {
      this.form.cityName = ''
      val.forEach((element, index) => {
        this.form.cityName = this.form.cityName + element.name
      });
      this.show = false
    },
    onSubmit() {

      this.form.intentionDetail = ''
      this.want.forEach(element => {
        if (element.check) {
          this.form.intentionDetail = this.form.intentionDetail + element.str + ','
        }
      });
      if (!this.$utils.isNoEmptyStr(this.form.cityName)) {
        this.$Toast('请选择城市')
        return
      }
      if (!this.$utils.isNoEmptyStr(this.form.companyName)) {
        this.$Toast('请填写公司名称')
        return
      }
      if (!this.$utils.isNoEmptyStr(this.form.userName)) {
        this.$Toast('请填写您的姓名')
        return
      }
      if (!this.$utils.isNoEmptyStr(this.form.intentionDetail)) {
        this.$Toast('请选择购买意向')
        return
      }
      this.$api
        .addCompanyPurchase(this.form)
        .then((res) => {
          if (res.code == 0) {
            this.$Toast('提交成功');
          } else {
            this.$Toast(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() { },
}

</script>
<style  lang="scss" scoped>
.page {
  height: 100vh;
  overflow: hidden;
}

.title {
  font-size: 1.1rem;
  margin: 1rem;

  .context {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #666;
    margin-left: 0.5rem;
  }

  .content {
    background-color: white;
    border-radius: 0.8rem;
    padding: 0 0.5rem;
    margin-top: 1rem;

    .item {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #DDD;
      padding: 0.2rem 0;
      align-items: center;

      .lefttitle {
        display: flex;
        flex-direction: row;
        min-width: 6rem;
        align-items: center;
      }
    }

    .red {
      color: rgb(225, 1, 1);
      margin-left: 0.5rem;
    }

    .want {
      margin-top: 0.5rem;
    }

    .wantcontent {
      padding: 0.5rem 0;

      .wantitem {
        background-color: #EEE;
        padding: 0.2rem 0.5rem;
        margin: 0.5rem 0.5rem 0.5rem 0;
        border-radius: 0.2rem;
        font-size: 0.85rem;
        display: inline-block;
      }

      .wantitemSelect {
        background-color: #EEE;
        padding: 0.2rem 0.5rem;
        margin: 0.5rem 0.5rem 0.5rem 0;
        border-radius: 0.2rem;
        font-size: 0.85rem;
        color: rgb(225, 1, 1);
        display: inline-block;
      }
    }
  }
}

.foot-btn {
  width: 100%;
  margin-top: 2.85rem;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  line-height: normal; //安卓字体偏上
  text-align: center;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  font-size: 1.1rem;
}
</style>